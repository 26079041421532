<template>
  <main class="main" data-view="home">
    <section class="section" id="s1">
      <firework v-if="$store.state.ww >= 992" />
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <ui-title tag="1" class="lh-125 ta-center mb-4">
              <span>{{ cc.s1.title[0] }} </span>
              <br class="d-none d-md-block"/>
              <span>{{ cc.s1.title[1] }}</span>
              <span class="sticker sticker--green" v-if="$store.state.ww >= 992">
                {{ cc.s1.sticker }}
              </span>
            </ui-title>
            <ui-text tag="3" class="tc-gray lh-150 ta-center mb-5 pb-lg-4">
              <span>{{ cc.s1.text[0] }} </span>
              <br class="d-none d-md-block"/>
              <span>{{ cc.s1.text[1] }}</span>
            </ui-text>
            <div v-if="$store.state.ww < 992" class="d-flex justify-content-center" style="position: relative">
              <firework />
              <span class="sticker sticker--green">
                {{ cc.s1.sticker }}
              </span>
            </div>
            <div class="d-flex flex-wrap flex-row justify-content-center align-items-center">
              <ui-btn :data="cc.s1.btn[0]" type="1" class="btn-w-160 btn-w-sm-200 btn-s1 btn-sm-s2 mb-3 mx-2"/>
              <ui-btn :data="cc.s1.btn[1]" type="2" class="btn-w-160 btn-w-sm-200 btn-s1 btn-sm-s2 mb-3 mx-2"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s-partners">
      <ui-title tag="5" class="lh-125 ta-center mb-4">
        <span>Podporujeme :</span>
      </ui-title>
      <partners />
    </section>
    <section class="section" id="s2">
      <div class="container">
        <div class="row flex-column-reverse flex-lg-row">
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 pe-lg-5">
            <wgt-calculator />
          </div>
          <div class="col-12 col-lg-6 ps-lg-3 mb-5 mb-lg-0">
            <div class="wrapper d-flex flex-column justify-content-center">
              <ui-title tag="2" class="ta-center ta-lg-start mb-4">
                {{ cc.s2.title }}
              </ui-title>
              <ui-text tag="3" class="ta-center ta-lg-start tc-gray lh-35 mb-4 mb-md-5">
                {{ cc.s2.text }}
              </ui-text>
              <ui-link :data="cc.s2.link[0]" modifier="arrow-right" class="mx-auto mx-lg-0 ts-16 ts-md-20" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section d-none" id="s3">
      <div class="container">
        <div class="row mb-4 mb-md-5 pb-lg-5">
          <div class="col-12 col-md-10 offset-md-1 mb-4 mb-md-0">
            <ui-title tag="2" class="lh-125 ta-center mb-4 mx-lg-4 px-md-5">
              {{ cc.s3.title }}
            </ui-title>
            <ui-text tag="3" class="tc-gray lh-175 ta-center mb-4 mb-md-5">
              {{ cc.s3.text }}
            </ui-text>
            <div class="d-flex justify-content-center">
              <ui-link :data="cc.s3.link[0]" modifier="arrow-right" class="ts-16 ts-md-20" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <!--<wgt-history />-->
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow" id="s4">
      <div class="container">
        <div class="row mb-4 mb-md-5">
          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <ui-title tag="2" class="ta-center mb-4">
              {{ cc.s4.title }}
            </ui-title>
            <ui-text tag="3" class="tc-gray lh-175 ta-center">
              {{ cc.s4.text }}
            </ui-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4" v-for="(list, i) in cc.s4.list.items" :key="`s4-col-item-${i}`">
            <ui-list type="iconed" :list="list"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s5">
      <div class="container mb-4 mb-md-5">
        <div class="row">
          <div class="col-12">
            <ui-title tag="2" class="ta-center">
              {{ cc.s5.title }}
            </ui-title>
          </div>
        </div>
      </div>
      <comp-slider :slider="cc.s5.slider"/>
    </section>
    <section class="section" id="s6">
      <comp-banner-app />
    </section>
    <section class="section section--snow" id="s7">
      <comp-contact />
    </section>
  </main>
</template>
<script>
import compBannerApp from '@/components/Banners/BannerApp'
import compContact from '@/components/Contact'
import compSlider from '@/components/Slider'
import firework from '@/components/Firework'
import partners from '@/components/Partners'
// import wgtHistory from '@/components/Widgets/CryptoHistory'
import wgtCalculator from '@/components/Widgets/Calculator'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiList from '@/components/UI/List'
import uiBtn from '@/components/UI/Btn'
import uiLink from '@/components/UI/Link'
export default {
  components: {
    compBannerApp, compContact, compSlider, firework, partners, wgtCalculator, uiTitle, uiText, uiList, uiBtn, uiLink // , wgtHistory
  },
  data () {
    return {
      chartData: {
        Books: 24,
        Magazine: 30,
        Newspapers: 10
      }
    }
  },
  computed: {
    cc () {
      return this.$t('pages.hp')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.home')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
