<template>
  <div class="wgt-calculator">
    <div class="row">
      <div class="col-12">
        <p class="text ts-20 tw-500 ta-center ta-lg-start">{{ cc.title }}</p>
      </div>
    </div>
    <div class="row my-4 pb-2">
      <div class="col-12">
        <div class="wgt-calculator__workspace">
          <div class="wgt-calculator__row" :class="[{'first': !isFlipped}, {'flipping': isFlipping}]">
            <div class="wgt-calculator__input">
              <input type="text" v-model="input_1" @input="handleInputA" placeholder="0" pattern="[0-9]*" :class="{'active': isChanging}">
              <input type="text" v-model="selCrypto" disabled>
              <div class="wgt-calculator__select">
                <div class="wgt-calculator__select-custom">
                  <input type="checkbox" id="ckbx-sel-1" />
                  <div class="sel-cust__head">
                    <label class="sel-cust__item" for="ckbx-sel-1">
                      <i class="sel-cust__icon">
                        <img :src="'/img/icons/crypto/' + selCrypto + '.svg'" :alt="selCrypto" />
                      </i>
                      <p class="sel-cust__text">{{ selCrypto.toUpperCase() }}</p>
                    </label>
                  </div>
                  <div class="sel-cust__body">
                    <label class="sel-cust__item" for="ckbx-sel-1" @click="selCrypto = i"
                           v-for="(item, i) in cryptoActualSelected" :key="'sel-item-' + i">
                      <i class="sel-cust__icon">
                        <img :src="'/img/icons/crypto/' + i.toLowerCase() + '.svg'" :alt="i" />
                      </i>
                      <p class="sel-cust__text">{{ item.name }}</p>
                    </label>
                  </div>
                </div>
                <div class="wgt-calculator__select-default">
                  <select name="sel-crypto" id="sel-crypto" v-model="selCrypto">
                    <option v-for="(item, i) in cryptoActualSelected" :key="'sel-item-' + i"
                            :value="i">
                      <template v-if="$store.state.ww >= 576">{{ item.name }}</template>
                      <template v-else>{{ i.toUpperCase() }}</template>
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="wgt-calculator__flip" :class="{'flipping': isFlipping}">
            <button @click="flipInputs()" :class="{'rotating': isRotating}">
              <img src="/img/icons/ui/ico-wgt-calculator-flip-btn-part-2.svg" alt="flip">
              <img src="/img/icons/ui/ico-wgt-calculator-flip-btn-part-1.svg" alt="flip">
            </button>
          </div>
          <div class="wgt-calculator__row" :class="[{'first': isFlipped}, {'flipping': isFlipping}]">
            <div class="wgt-calculator__input">
              <input type="text" v-model="input_2" @input="handleInputB" placeholder="0" pattern="[0-9]*" :class="{'active': isChanging}">
              <input type="text" v-model="sel_currency" disabled>
              <div class="wgt-calculator__select">
                <div class="wgt-calculator__select-custom">
                  <input type="checkbox" id="ckbx-sel-2" />
                  <div class="sel-cust__head">
                    <label class="sel-cust__item" for="ckbx-sel-2">
                      <i class="sel-cust__icon">
                        <img :src="'/img/icons/currency/ico-' + sel_currency.toLowerCase() + '.svg'" :alt="sel_currency" />
                      </i>
                      <p class="sel-cust__text">{{ sel_currency.toUpperCase() }}</p>
                    </label>
                  </div>
                  <div class="sel-cust__body">
                    <label class="sel-cust__item" for="ckbx-sel-2" @click="sel_currency = item"
                           v-for="(item, i) in currencyList" :key="'sel-item-curr-' + i">
                      <i class="sel-cust__icon">
                        <img :src="'/img/icons/currency/ico-' + item.toLowerCase() + '.svg'" :alt="item" />
                      </i>
                      <p class="sel-cust__text">{{ item.toUpperCase() }}</p>
                    </label>
                  </div>
                </div>
                <div class="wgt-calculator__select-default">
                  <select name="sel-crypto" id="sel-currency" v-model="sel_currency">
                    <option v-for="(item, i) in currencyList" :key="'sel-item-curr-' + i"
                            :value="item">{{ item.toUpperCase() }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="wgt-calculator__result">
          <p class="text tc-yellow ts-16 tw-500 ta-center mb-2">{{ cc.text }}</p>
          <p class="text ts-16 tw-500 ta-center">
            <template v-if="input_1 === null">0</template>
            {{ input_1 }} {{ selCrypto.toUpperCase() }} =
            <template v-if="input_2 === null">0</template>
            {{ input_2 }} {{ sel_currency.toUpperCase() }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isFlipped: false,
    isFlipping: false,
    isRotating: false,
    isReversed: false,
    isChanging: false,
    input_1: null,
    input_2: null,
    selCrypto: 'btc',
    sel_currency: 'CZK',
    currencyList: ['CZK'],
    cryptoNames: {
      btc: 'Bitcoin',
      bch: 'Bitcoin Cash',
      ltc: 'Litecoin',
      eth: 'Ethereum',
      xrp: 'Ripple'
    }
  }),
  methods: {
    flipInputs () {
      this.isChanging = true
      setTimeout(() => { this.isChanging = false }, 600)
      setTimeout(() => {
        if (this.isReversed) {
          this.input_1 = this.input_2
          this.handleInputA()
        } else {
          this.input_2 = this.input_1
          this.handleInputB()
        }
        this.isReversed = !this.isReversed
      }, 300)
      this.isFlipped = !this.isFlipped
      this.isFlipping = true
      this.isRotating = true
      setTimeout(() => { this.isFlipping = false }, 350)
      setTimeout(() => { this.isRotating = false }, 600)
    },
    handleInputA () {
      this.input_1 = this.input_1.replace(/[^\d]/g, '')
      this.input_2 = parseFloat(this.input_1 * this.price).toFixed(2)
    },
    handleInputB () {
      this.input_2 = this.input_2.replace(/[^\d]/g, '')
      this.input_1 = parseFloat(this.input_2 / this.price).toFixed(7)
    }
  },
  computed: {
    cc () {
      return this.$t('widget.calculator')
    },
    cryptoActualApi () {
      return this.$store.state.cryptoActualApi
    },
    cryptoActualSelected () {
      const cryptoActualApi = this.cryptoActualApi
      const res = {}
      for (let i = 0; i < cryptoActualApi.length; i++) {
        const key = cryptoActualApi[i].key
        const price = cryptoActualApi[i].price
        const name = cryptoActualApi[i].name
        res[key] = { key, price, name }
      }
      return res
    },
    price () {
      return this.cryptoActualSelected[this.selCrypto].price
    }
  },
  watch: {
    selCrypto: function () {
      this.input_2 = (this.input_1 * this.price).toFixed(4)
    },
    sel_currency: function () {
      this.input_2 = (this.input_1 * this.price).toFixed(4)
    }
  }
}
</script>
