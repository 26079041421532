<template>
  <div class="firework">
    <div class="container">
      <div class="firework__wrapper">
        <div class="firework__icons">
          <div class="firework__icons-item firework__icons-item--crypto" v-for="(item, i) in icons" :key="`firework-item-${i}`">
            <img :src="`/img/icons/crypto/${item}.svg`" :alt="item" />
          </div>
          <div class="firework__icons-item firework__icons-item--squire" v-for="i in 3" :key="`squire-${i}`">
            <img src="/img/components/firework/p-squire.svg" alt="squire" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    // icons: ['btc', 'xlm', 'bch', 'eth', 'trx', 'ltc', 'matic']
  }),
  computed: {
    icons () {
      const arr = []
      const tmp = this.$store.state.cryptoList
      for (let n = 0; n < 2; n++) {
        tmp.forEach(item => {
          arr.push(item)
        })
      }
      return arr
    }
  }
}
</script>
