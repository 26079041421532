<template>
  <div>
    <div class="slider">
      <div class="slider__control mx-auto mb-4 mb-md-5 pb-lg-4">
        <div class="slider__control-wrapper">
          <span class="slider__control-tape" id="tape"></span>
          <div class="slider__control-item" v-for="(slide, s) in slider" :key="'control-' + s" :id="'control-' + s" :class="{'active': current === s}">
            <p class="slider__control-text ts-12 ts-md-14 ts-lg-20 ta-center" @click="goToSlide(s)">{{ slide.tab }}</p>
          </div>
        </div>
      </div>
      <div class="slider__container" id="slider-container">
        <div class="slider__section" v-for="(slide, s) in slider" :key="'slider-section-' + s" :class="{'active': current === s}" :id="'slide-' + s">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <ui-text tag="3" class="tc-waikawa tw-500 lh-150 ta-center ta-md-start mb-3 mb-md-4">
                  {{ slide.pre }}
                </ui-text>
              </div>
              <div class="col-12 col-md-6 col-xl-6 pe-xl-4 mb-5 mb-md-0">
                <ui-title tag="3" class="lh-125 ta-center ta-md-start mb-4 mb-md-5">
                  {{ slide.title }}
                </ui-title>
                <ui-text tag="3" class="tc-gray lh-175 ta-center ta-md-start mb-4 mb-md-5">
                  {{ slide.text }}
                </ui-text>
                <div class="img img--slider" :data-slide="s + 1">
                  <img :src="`/img/components/slider/img-slider-${s + 1}.png`" :alt="`slider-img-${s + 1}`" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6 ps-xl-4">
                <ui-list :list="slide.list.items" type="numbered" :theme="slide.list.theme" modifier="" spaceClass="mb-4 mb-md-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiList from '@/components/UI/List'
export default {
  props: {
    slider: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    current: 0
  }),
  components: {
    uiTitle, uiText, uiList
  },
  methods: {
    goToSlide (i) {
      if (this.current !== i) this.current = i
      this.changeSlide(i)
      this.changeTape(i)
    },
    changeSlide (i) {
      const container = document.getElementById('slider-container')
      const offset = 50 * i
      container.style.transform = 'translateX(-' + offset + '%)'
      container.style.height = this.containerHeight + 'px'
    },
    changeTape (i = this.current) {
      const tape = document.getElementById('tape')
      const translate = i !== 0 ? (this.tapeOffset() * this.current) + 5 : 5
      console.log(translate)
      setTimeout(() => {
        tape.style.width = this.tapeWidth() + 'px'
        tape.style.transform = 'translate(' + translate + 'px, 5px)'
      }, 0)
    },
    tapeWidth () {
      const element = document.getElementById('control-' + this.current)
      return element.clientWidth
    },
    tapeOffset () {
      const element = document.getElementById('control-' + this.current)
      return element.clientWidth
    }
  },
  computed: {
    containerHeight: function () {
      const element = document.getElementById('slide-' + this.current)
      return element.clientHeight
    },
    cc () {
      return this.$t('pages.accounts.deposit')
    }
  },
  watch: {
    '$i18n.locale' () {
      setTimeout(() => {
        this.changeTape()
      }, 600)
    },
    '$store.state.ww' () {
      setTimeout(() => {
        this.changeTape()
      }, 600)
    }
  },
  created () {
    setTimeout(() => {
      this.changeTape(0)
    }, 200)
  }
}
</script>
