<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-10 offset-xl-1">
        <div class="partners">
          <div v-for="(item, i) in partners" :key="`partner-${i}`">
          <a :href="links[i]" target="_blank" class="partners__img">
            <img :src="`/img/components/partners/img-partner-${i + 1}.png`" :alt="partners[i]" />
            <img :src="`/img/components/partners/img-partner-${i + 1}-c.png`" :alt="partners[i]" />
          </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    partners: ['HC Sparta Praha', 'Wallachia classic', 'AC Sparta', 'Barvy léta', 'Pertovice fest'],
    links: ['https://www.hcsparta.cz/', 'https://www.mwc.cz/', 'https://www.sparta.cz/', 'https://www.barvyleta.cz/', 'https://petrovicefest.cz/']
  })
}
</script>
